<h4 class="md-dialog-title">
  <span>Galería de Imágenes</span>
  <a (click)="onCloseClick()">X</a>
</h4>
<div class="md-dialog-content mat-dialog-container--images-gallery">
  <div class="slider images">
    <div class="image" *ngFor="let image of data.images;">
      <img src="{{image.url}}"/>
    </div>
  </div>
</div>
