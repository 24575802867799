<div class="user" (click)="toggleMenu()">
  <div class="input-holder" *ngIf="showTreatmentTypeSelector">
    <label>
      <select (change)="onTreatmentTypeChange($event)">
        <option *ngFor="let treatmentType of treatmentTypeList" [value]="treatmentType.id"
                [label]="treatmentType.name" [selected]="treatmentType.id === this.authService.getTreatmentTypeSelectedValue()"></option>
      </select>
      <span class="select-caret"></span>
    </label>
  </div>
  <div class="user__image" *ngIf="authService.getUserLogged().image"
       [style.background-image]="'url(' + authService.getUserLogged().image + ')'"></div>
  <div class="user__email">{{authService.getUserLogged().email}}</div>
  <a (click)="logout()"><i class="fa fa-sign-out"></i>{{ 'HEADER.LOGOUT' | translate }}</a>
</div>


