<h4 class="md-dialog-title">
  <span>Comparar Lotes</span>
  <a (click)="onCloseClick()">X</a>
</h4>
<div class="md-dialog-content">
  <form novalidate [formGroup]="plotCompareForm" (submit)="onSubmit()">
    <fieldset>
      <div class="input-holder">
        <label>Lote 1
          <select formControlName="plot1">
            <option *ngFor="let plot of plots" [value]="plot.id"
                    [label]="plot.name"></option>
          </select>
          <span class="select-caret"></span>
        </label>
      </div>
      <div class="input-holder">
        <label>Lote 2
          <select formControlName="plot2">
            <option *ngFor="let plot of plots" [value]="plot.id"
                    [label]="plot.name"></option>
          </select>
          <span class="select-caret"></span>
        </label>
      </div>
    </fieldset>
    <div class="form__buttons">
      <a class="button button--secondary" (click)="onCloseClick()">Cancelar</a>
      <button class="button" [disabled]="plotCompareForm.invalid">Comparar</button>
    </div>
  </form>
</div>
